import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

class Privacy extends React.Component {
  render() {
    return (
      <div className="termsAndConditions">
        <Helmet>
          <title>Pravila zaštite podataka i privatnosti</title>
        </Helmet>
        <Container>
          <Link className="terms" to="/">
            Početna
          </Link>
          <h1 className="pageTitle">Pravila zaštite podataka i privatnosti</h1>
          <p>
            Sukladno s Općom uredbom o zaštiti podataka (GDPR) koja je u
            Republici Hrvatskoj stupila na snagu 25. svibnja 2018. uredili smo
            pravila o privatnosti. <br />
            Terraform, vl. Luka Grgić, OIB: 36670123504, obavezuje se pružati
            zaštitu osobnim podacima kupaca, na način da prikuplja samo nužne,
            osnovne podatke o kupcima/ korisnicima koji su nužni za ispunjenje
            naših obveza; informira kupce o načinu korištenja prikupljenih
            podataka, redovito daje kupcima mogućnost izbora o upotrebi njihovih
            podataka, uključujući mogućnost odluke žele li ili ne da se njihovo
            ime ukloni s lista koje se koriste za marketinške kampanje. Svi se
            podaci o korisnicima strogo čuvaju i dostupni su samo djelatnicima
            kojima su ti podaci nužni za obavljanje posla. Terraform, vl. Luka
            Grgić, OIB: 36670123504, i poslovni partneri odgovorni su za
            poštivanje načela zaštite privatnosti. <br />
            1. PRIKUPLJANJE I KORIŠTENJE INFORMACIJA <br /> 1.1. Terraform štiti
            i cijeni Vašu privatnost. <br />
            1.2. U procesu realizacije tražene usluge i za davanje obavijesti i
            informacija vezanih uz Vašu kupovinu ili drugu uslugu, koristit ćemo
            samo one informacije koje nam svojevoljno date. Obvezujemo se da
            nećemo prodati, iznijeti ili dati ove informacije trećoj stranci na
            način koji nije naveden u ovoj izjavi. <br />
            2. REGISTRACIJA <br /> 2.1. Kako bi obavio proces kupnje ili poslao
            upit na www.terraform.hr internet stranicama, korisnik prethodno
            obavlja registraciju popunjavanjem registracijskog upitnika. Tijekom
            registracije od korisnika se traže kontakt informacije (ime i
            prezime, e-mail adresa, adresa stanovanja, broj za kontakt). Ove se
            informacije koriste za stupanje u kontakt sa korisnikom prilikom
            odgovaranja na pitanja postavljena na našim Internet stranicama te
            za osiguravanje privatnosti korisnika pri kontroli statusa kupnje i
            unosa ili promjene podataka. <br />
            2.2. Korisnik može poslati upit i bez podataka a samo sa ispravno
            upisanom email adresom. <br /> 3. VODITELJ OBRADE PODATAKA <br />{" "}
            3.1. Terraform, vl. Luka Grgić na adresi Dunavska 14, 35000
            Slavonski Brod je voditelj obrade vaših osobnih podataka. <br />
            3.2. Politika privatnosti se primjenjuje na sve osobne podatke
            korisnika koje prikupljamo i obrađujemo, izravno ili putem svojih
            partnera.
            <br />
            3.3. Osobni podatak je svaki podatak koji se odnosi na fizičku osobu
            čiji je identitet utvrđen ili se može utvrditi, izravno ili
            neizravno.
            <br />
            3.4. Obrada podataka je bilo koja radnja izvršena na osobnim
            podacima, poput primjerice prikupljanja, pohrane, upotrebe, uvida i
            prijenosa osobnih podataka. <br />
            4. KOJE PODATKE PRIKUPLJAMO <br />
            4.1. Pri procesu kupnje ili rezervaciji robe na ovim stranicama,
            tražit ćemo osobne podatke korisnika tj. kupca (poput imena i
            prezimena, adrese, kontakt telefona, spol, datum rođenja i sl.). Ti
            podaci koristit će se isključivo za realizaciju usluge. Podaci o
            plaćanju (poput broja kartice, i sl.) posebno su zaštićeni i bit će
            dostupni samo bankama, odnosno kartičnim kućama radi autorizacije
            plaćanja, nedostupni obrtu Terraform, a dostupni samo ovlaštenoj
            tvrtki za prikupljanje takvih podataka. <br />
            4.2. Također i informacije o prodaji: informacije o kupnji,
            uključujući korisnički identitet kupca, reklamacije, informacije o
            dostavi, podršci i uslugama, uključujući prigovore i zahtjeve.{" "}
            <br />
            5. SVRHA PRIKUPLJANJA PODATAKA Prodaja proizvoda – za obradu
            zahtjeva kupaca. Terraform će prikupiti osobne podatke, informacije
            o prodaji, kao i informacije o povijesti kupca i obavještavanje o
            pitanjima vezanim uz vaš proizvod. Podrška kupcima i marketing – za
            odgovore na upite i davanje novosti i ponuda. <br />
            6. PRIJENOS TREĆIM STRANAMA <br />
            6.1.Osobni podaci mogu se prenositi trećim stranama, pod uvjetom da
            postoji pravna osnova za prijenos. <br />
            6.2. Vaše osobne podatke koristimo prilikom obrade i realizacije
            vaše narudžbe i prilikom obrade plaćanja. Vaše osobne podatke (ime,
            prezime, adresu, e-mail adresu i broj mobitela) dijelimo s dostavnim
            službama kako bi oni mogli izvršiti dostavu naručenih proizvoda.
            Vaši osobni podaci zaštićeni su ugovorom kojeg imamo s dostavnom
            službom i neće se upotrebljavati ni za što drugo osim za izvršavanje
            dostave paketa. <br />
            7. KOLIKO DUGO ZADRŽAVAMO VAŠE OSOBNE PODATKE Osobne podatke
            pohranjujemo i obrađujemo onoliko dugo koliko je potrebno za
            izvršenje određene legitimne svrhe, osim ako važećim propisima nije
            za pojedinu svrhu predviđeno duže vrijeme čuvanja, a najmanje za
            vrijeme trajanja ugovora između korisnika i Terraform, vl. Luka
            Grgić. U slučaju davanja privole za marketing, podatke čuvamo
            onoliko dugo dok se privola ne povuče. Osobni podaci koji više nisu
            potrebni ili korisnik zatraži njihovo brisanje uništavaju se na
            siguran način. Google Analytics čuva podatke u trajanju od 26
            mjeseci, a podaci se koriste isključivo za praćenje statistika
            posjećenosti internetske stranice. <br />
            8. WEBSHOP <br />
            8.1. Pristup web shopu www.terraform.hr moguć je s ili bez
            registracije. <br />
            8.2. Prilikom registracije fizičke osobe na stranicama
            www.terraform.hr od Vas tražimo određene osobne podatke kao što su:
            ime i prezime, email adresa, adresa stanovanja, telefonski broj,
            datum rođenja u svrhu pružanja raznih pogodnosti. Pri kupovini na
            stranicama www.terraform.hr bez registracije prikupljamo podatke
            koji su nužni za isporuku kupljene robe te se navedeni podaci
            pohranjuju u svrhu ispunjenja ugovora. <br />
            8.3. Korisnik naših web stranica može izabrati želi li primati
            promotivne poruke ili ne pritiskom na polje potvrde, neovisno o tome
            je li se registrirao na stranici ili ne.
            <br />
            8.4. Davanje osobnih podatka je odluka korisnika koji može ali i ne
            mora koristiti pogodnosti naše tvrtke. Ukoliko korisnik odluči ne
            pružiti podatke potrebne za određenu aktivnost, ista neće moći biti
            provedena. <br /> 8.5. Podaci koje korisnik daje pri registraciji,
            pohranjuju se na serveru webshopa, te u poslovnom programu kojem
            pristup imaju djelatnici tog odjela uz šifre za pristup poslovnom
            programu. <br />
            8.6. U svakom trenutku možete deaktivirati vašu registraciju
            odabirom polja deaktivacije. U tom slučaju podaci će se čuvati u
            sustavu webshopa dok postoji potreba za izvršenjem legitimne svrhe,
            a najmanje za vrijeme trajanja ugovora između korisnika i
            Terraforma. <br />
            8.7. Preporučujemo našim korisnicima da pri izradi lozinke za
            korisnički račun kombiniraju velika, mala slova i broj kako bi sama
            lozinka bili sofisticiranija i teža za probijanje. Mi kao tvrtka
            ovim putem obavještavamo kupce da podatke kreditnih kartica kao i
            bilo koje podatke takvog tipa ne pohranjujemo niti vršimo obradu na
            našim stranicama nego na zaštićenim stranicama na kojima se sve vrši
            putem enkripcije i nama kao tvrtki ti podaci nisu vidljivi u
            izvornom obliku. <br />
            9. AŽURIRANJE OSOBNIH PODATAKA Korisnik u bilo kojem trenutku ima
            mogućnost pristupa svojim podacima pohranjenim na internetskim
            stranicama gdje ih može revidirati, mijenjati i brisati. Sve izmjene
            izvršene na internetskim stranicama bit će zabilježene i unutar
            samog poslovnog programa odmakom od 24 sata. <br />
            10. PRAVO NA ZABORAV OSOBNIH PODATAKA Korisnik ima pravo u bilo
            kojem trenutku zatražiti brisanje osobnih podataka. To može učiniti
            slanjem zahtjeva na e-mail adresu info@terraform.hr i podaci će biti
            izbrisani bez odgađanja, osim u slučaju da postoji zakonska obveza
            čuvanja navedenih podataka, legitimni interes te osnova za
            ostvarenje vaših zakonskih prava. <br />
            11. PRAVO NA PRIGOVOR Ukoliko unatoč svim poduzetim mjerama za
            zaštitu osobnih podataka smatrate da imate osnove za prigovor
            obratite se na email adresu info@terraform.hr. Uz prijavu nama,
            možete prijavu podnijeti i Agenciji za zaštitu osobnih podataka.
            <br /> 12. NEWSLETTER U slučaju da korisnik želi primati newsletter
            (obavještavamo Vas o akcijama, popustima i novostima), od njega će
            biti zatražena samo valjana e-mail adresa na koju će se newsletter
            slati te će takav korisnik dati Privolu da njegove osobne podatke
            možemo obrađivati isključivo u svrhu slanja newsletter-a. <br />
            13. AŽURIRANJE OSOBNIH PODATAKA Ako se korisnikovi osobni podaci
            promijene ili ako korisnik više ne želi našu uslugu, omogućujemo
            ispravljanje, ažuriranje ili uklanjanje korisnikovih osobnih
            podataka koje posjedujemo. To se obično može izvršiti na stranicama
            korisničkog profila. <br />
            14. PRAVILA O POSTUPANJU SA KOLAČIĆIMA <br />
            14.1. Kolačić (cookie) je podatak koji web stranica pohranjuje u
            računalo ili mobilni uređaj posjetitelja. Kolačić dozvoljava web
            stranici da pamti akcije posjetitelja iz njegovih posjeta. Većina
            preglednika dozvoljava upotrebu kolačića, ali korisnik u svakom
            trenutku može kolačiće obrisati ili postaviti u pregledniku zabranu
            upotrebe kolačića. Najčešće razlozi upotrebe kolačića su
            identifikacija korisnika, pamćenje preferencija korisnika, pomoć pri
            unosu ili pregledu sadržaja koji je već unašan u prijašnjim
            posjetima, skupljanje podataka za analize i promotivne kampanje.{" "}
            <br />
            14.2. Prilikom uporabe kolačića određeni podaci se prikupljaju
            automatski s vašeg računala pri dolasku na naše stranice. Ti podaci
            mogu uključivati i IP adresu, datum i vrijeme pristupe, podatke o
            vrsti računala, operativnog sustava, pretraživaču koji koristite,
            verzijama programa kao i jezičnim postavkama vašeg računala.
            Pristupom na samu stranicu vidjet ćete obavijest o korištenju
            kolačića koji prikupljaju ove podatke te pritiskom na polje „slažem
            se“ dajete privolu za prikupljanje tih podataka. Ukoliko na
            stranicama ne date privolu, ti podaci neće biti prikupljani,
            pohranjeni i obrađivani. 14.3. U postavkama pretraživača možete
            odrediti koje kolačiće želite prihvatitI, a koje odbiti. Mjesto
            uređivanja postavki ovisi o vrsti pretraživača, a može se naći u
            uputama za svaki pojedini pretraživač. Ukoliko odlučite da ne želite
            prihvaćati kolačiće sa Terraform webshopa, postoji mogućnost da
            nećete moći koristiti određene funkcionalnosti web stranice.
          </p>
        </Container>
      </div>
    );
  }
}

export default Privacy;
